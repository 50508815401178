import React, { Component, Fragment } from "react";
//import Slider from "react-slick";
//import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
//import CounterOne from "../elements/counters/CounterOne";
//import Testimonial from "../elements/Testimonial";
//import Team from "../elements/Team";
//import BlogContent from "../elements/blog/BlogContent";
//import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "it takes one to know one ",
    description: "A COMMUNITY FOR WOMEN TECH FOUNDERS IN CANADA",
    buttonText: "Contact Us",
    buttonLink: "#contact"
  }
];

class CreativeLanding extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function() {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}

  render() {
    //const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function() {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function() {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="Women Tech Founders" />
        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    style={{ width: 212, height: 42 }}
                    src="/assets/images/wtf-logo-light.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    style={{ width: 212, height: 42 }}
                    src="/assets/images/wtf-logo-all-dark.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "about", "membership", "founders", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a href="#home" onClick={this.CLoseMenuTrigger}>
                      Home
                    </a>
                  </li>

                  <li>
                    <a href="#about" onClick={this.CLoseMenuTrigger}>
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#membership" onClick={this.CLoseMenuTrigger}>
                      Membership
                    </a>
                  </li>

                  <li>
                    <a href="#founders" onClick={this.CLoseMenuTrigger}>
                      Founders
                    </a>
                  </li>

                  <li>
                    <a href="#contact" onClick={this.CLoseMenuTrigger}>
                      Contact
                    </a>
                  </li>
                </Scrollspy>
              </nav>

              {/* Start Hamburger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Hamburger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency" id="home">
          <div className="bg_image bg_image--26" data-black-overlay="3">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex "
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h2 style={{ color: "white" }}>{value.title}</h2>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description" style={{ color: "white" }}>
                            {value.description}
                          </p>
                        ) : (
                          ""
                        )}

                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2  btn-primary-color"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Description Area  */}
        <div className="rn-columns-area ptb--60 bg_color--2 ">
          <div className="container">
            <div className="row ">
              <div className="col-lg-12">
                <div className="single-column text-center">
                  <h2 style={{ color: "white" }}>
                    Our shared experience creates the Collective
                  </h2>
                  <p style={{ color: "white" }}>
                    Every founder has a set of unique experiences and a drive to
                    achieve.<br></br>We create opportunity for Women Tech
                    Founders in Canada to leverage this collective, regardless
                    of the stage of their company.
                  </p>
                  <h3 style={{ color: "white" }}>
                    Connect, Grow, Lead... Be Empowered!
                  </h3>
                  <br></br>
                  <div className="slide-btn">
                    <a className="rn-button-style--2 " href="/members">
                      Become a Member
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Description Area  */}

        {/* Start About Area  */}
        <div className="container ptb--120" id="about">
          <div className="row">
            <div className="col-lg-12">
              <div className="single-column text-center">
                <h2 className="tilte">About WTF</h2>

                <p>
                  We are women tech founders. We build and lead companies in
                  STEM disciplines.
                </p>
                <p className="description">
                  Led by founders of technology companies in Canada, wtfCAN –
                  Women Tech Founders of Canada – is a trusted peer group for
                  ongoing support that leverages the collective expertise and
                  experience to help each other get connected, lead, grow, and
                  feel empowered. <br></br>
                  Our first mission was to understand what gap we were filling
                  in a tech community loaded with organizations to further the
                  advancement of women, both in and out of tech. We selected a
                  group of 8 female founders, representing various stages of
                  startup development, from pre-revenue to $10 Million in annual
                  revenues (all incorporated), and asked them what they needed
                  and how a group like this could help them. Of particular
                  excitement right now is the energy building in the BCTech
                  community to support women in tech. We will be working to
                  ensure this group is complementary to the programming offered
                  there, and that we also share opportunities that may be of
                  interest to this community as their initiative continues to
                  grow.
                </p>
                <p className="description">
                  If you are a women founder, and are interested in joining us,
                  we’d love to hear from you!
                </p>
              </div>
            </div>
          </div>
          <div className="service-area creative-service-wrapper  bg_color--1">
            <div className="container">
              <div className="rn-columns-area  "></div>
              <div className="row creative-service">
                <div className="col-lg-12">
                  <ServiceList
                    item="2"
                    column="col-lg-6 col-md-6 col-sm-6 col-12 text-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End About Area  */}

        {/* Start Membership Area  */}
        <div className="row" id="membership">
          <div className="col-lg-12">
            <div className="service-details-inner">
              <div className="inner">
                <div className="rn-service-details ptb--120 bg_color--1">
                  <div className="container">
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-4 col-12 order-1 order-lg-1">
                        <div className="thumb position-relative">
                          <div className="thumbnail">
                            <img
                              className="w-100"
                              src="/assets/images/wtf-become-a-member.jpg"
                              alt="About Images"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-12 order-2 order-lg-2">
                        <div className="details mt_md--30 mt_sm--30">
                          <h2 className="title">Become a Member</h2>
                          <p className="description">
                            Join this increasing network of women who have
                            shared experience in the successes and struggles of
                            being a tech founder. Membership is by approval to
                            ensure fit.
                          </p>
                          <p>
                            Membership is by approval to ensure fit. Eligibility
                            criteria include:
                          </p>

                          <br></br>

                          <ul className="liststyle">
                            <li>You must be a woman*.</li>
                            <li>
                              You must be a founder; co-founder; or past
                              founder.
                            </li>
                            <li>
                              Your tech (STEM) company must be incorporated and
                              located in Canada.
                            </li>
                          </ul>
                          <br></br>
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-solid"
                              href="#contact"
                            >
                              Contact Us to Become a Member
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* End Membership Area  */}

        {/* Start Founders Area  */}
        <div className="rn-team-area ptb--120 bg_color--1 " id="founders">
          <div className="container ">
            <h2 className="title">Meet the Founders</h2>
            <div className="row">
              <div className="col-lg-2 col-md-6 col-12">
                <div className="single-column">
                  <div
                    className="thumbnail"
                    style={{ width: 150, height: 150 }}
                  >
                    <img src={`/assets/images/kari.jpg`} alt="Kari Lamotte" />
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-12 col-12">
                <div className="single-column">
                  <h3>Kari Lamotte</h3>
                  <h4 className="tilte">CO-FOUNDER</h4>
                  <p>
                    With so many groups helping women to achieve in their tech
                    careers, why is it that women leading technology startups
                    often feel like something is missing? It’s a question I know
                    I’ve been trying to solve ever since founding my own tech
                    company and experiencing the feeling of being “lonely at the
                    top.”
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="single-column">
                  After running my own startup, Groupanizer, for 7 years, I was
                  fortunate enough to be selected as one of the delegates for
                  the 2015 TechWomen Canada initiative in Silicon Valley, where
                  20 Female Tech Leaders from across Canada were immersed in the
                  Valley Tech Scene for a few days. Great event that I highly
                  recommend you check out, if your company is in revenue and
                  scaling. During that conference, I met 19 other women leading
                  companies (mostly founders), several from Vancouver. I had
                  never heard of any of them before that event, and yet, we all
                  shared the sense of instant connection with each other. We
                  understood the challenges we each were facing and for the
                  first time, there was a sense of community in what we were all
                  trying to achieve. What an amazing feeling to suddenly have a
                  network of people who “get” each other! Coming back to
                  Vancouver, I decided to make a point of connecting to other
                  female founders, and when I did, I asked them if that feeling
                  was something unique to me. Every single female founder I
                  spoke to shared similar experiences. What was even more
                  interesting was that as I connected with these amazing women
                  on LinkedIn, I realized that they weren’t connected to each
                  other and there was an opportunity for a community to be
                  built. In Spring of 2017, I ran across a female founder who
                  shared my vision and was passionate about bringing awareness
                  to the unique difficulties you face as a female founder while
                  you build a company. Tammy Meyers is the Co-Founder and COO of
                  QuestUpon, and she joined me to co-found Women Tech Founders –
                  the organization of female founders you see today.
                </div>
              </div>
            </div>
            <br></br> <br></br>
            <div className="row">
              <div className="col-lg-2 col-md-6 col-12">
                <div className="single-column">
                  <div
                    className="thumbnail"
                    style={{ width: 150, height: 150 }}
                  >
                    <img src={`/assets/images/tammy.jpg`} alt="Kari Lamotte" />
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-12 col-12">
                <div className="single-column">
                  <h3>Tammy Meyers</h3>
                  <h4 className="tilte">CO-FOUNDER</h4>
                  <p>
                    I began my startup journey in 2011 with our location-based
                    augmented reality company, now known as QuestUpon. We
                    bootstrapped, did a few pivots along the way, hired staff,
                    joined accelerators, worked with advisors, wrote grants, won
                    awards, etcetera.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="single-column">
                  Through this amazing startup rollercoaster I found myself
                  noticing that I was often the only woman, (or one of the only
                  women), in the room, whether that be in our company, or with
                  advisory groups, investor meetings, and tech events. Some of
                  my previous career roles, (cable industry; heli-logging
                  industry), often had few women in them too. So working in a
                  male-dominated industry wasn’t exactly a new thing for me to
                  deal with. However, when I started learning about the
                  statistics of gender inequality in the tech industry, I
                  started paying more attention. In July 2016, the Canadian
                  Press did a feature on myself and other women entrepreneurs
                  disrupting the male-dominated tech industry. It was this
                  interview, and others that followed, that started me talking
                  about this topic more openly, and inspiring me to further
                  change in the industry. I saw a lot of ‘women in tech’ groups
                  popping up, and I joined one that was for C-level leaders. Yet
                  it wasn’t until I met Kari LaMotte, and we started talking
                  about our experiences of running our own startups and the
                  various challenges we’ve faced as women entrepreneurs that I
                  realized I’d never had this type of conversation with another
                  woman co-founder. It was so enlightening and inspiring to know
                  that the things I was going through were normal for women in
                  my role! How had I not come across these women
                  (founders/co-founders) before?! From this meeting, and others
                  that followed, Kari and I decided to co-found this group, WTF
                  – Women Tech Founders. We look forward to help each other get
                  connected, lead, grow, and feel empowered.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Founders Area  */}

        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          <Contact />
        </div>
        {/* End Contact Us */}

        {/* Start Brand Area */}
        <div className="bg_color--1 ptb--60">
          <div className="container">
            <div className="single-column text-center ">
              <img
                style={{ width: 320 }}
                src="/assets/images/brand_discovery_foundation.png"
                alt="Discovery Foundation"
              />
              <p>
                Women Tech Founders of Canada is proud to partner with the&nbsp;
                <a
                  className="rn-button-style--3 "
                  href="http://www.discoveryfoundation.ca/tep/tep-programs/"
                >
                  Discovery Foundation
                </a>
                &nbsp; in providing business education programs for Women Tech
                Founders.
              </p>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CreativeLanding;
