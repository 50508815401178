import React, { Component } from "react";
import axios from "axios";
import Spinner from "react-spinner-material";

class ContactTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
      isBusy: false,
      message: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    // alert(this.state.rnName);
    event.preventDefault();
    this.setState({ isBusy: true, message: "" });
    const data = {
      name: this.state.rnName,
      email: this.state.rnEmail,
      message: this.state.rnMessage,
      subject: this.state.rnSubject
      //time: getTime()
    };

    axios
      .post(
        "https://us-central1-womentechfounders.cloudfunctions.net/sendMail",
        data
      )
      .then(res => {
        // here will be code
        this.setState({ isBusy: false, message: "Message sent!" });

        // alert("Message sent!");

        //alert(res.data.toString());
      })
      .catch(error => {
        this.setState({
          isBusy: false,
          message: "There was a problem sending message, please try again."
        });
        // alert("There was a problem sending message, please try again later.");
        // console.log(
        //   "There was a problem submitting your information, please try again later."
        // );
      });
  }

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us</h2>
                <p className="description">
                  If you’d like more information about wtfCAN, or are interested
                  in becoming a member, reach out to us! We’ll look forward to
                  hearing from you.
                </p>
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.rnName}
                      onChange={e => {
                        this.setState({ rnName: e.target.value });
                      }}
                      placeholder="Your Name *"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="text"
                      name="email"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={e => {
                        this.setState({ rnEmail: e.target.value });
                      }}
                      placeholder="Your email *"
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      value={this.state.rnSubject}
                      onChange={e => {
                        this.setState({ rnSubject: e.target.value });
                      }}
                      placeholder="Write a Subject"
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      value={this.state.rnMessage}
                      onChange={e => {
                        this.setState({ rnMessage: e.target.value });
                      }}
                      placeholder="Your Message"
                    />
                  </label>
                  {this.state.isBusy ? (
                    <Spinner
                      radius={40}
                      color={"#d91b5c"}
                      stroke={2}
                      visible={true}
                    />
                  ) : (
                    <button
                      className="rn-button-style--2 btn-solid"
                      type="submit"
                      value="submit"
                      name="submit"
                      id="mc-embedded-subscribe"
                    >
                      Submit
                    </button>
                  )}
                  <p className="description" style={{ paddingTop: 10 }}>
                    {this.state.message}
                  </p>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  src="/assets/images/wtf-contact-us.jpg"
                  alt="Contact Us Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
